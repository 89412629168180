/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2020-11-11 18:07:24
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-05-20 12:44:01
 */
import VueI18n from "vue-i18n"
import Vue from "vue"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || "en-US", //语言标识
  messages: {
    "en-US": require("./locales/en-US.json"), // 英文
    "id-IDN": require("./locales/id-IDN.json"), // 印尼语
  },
  fallbackLocale: "en-US",
})
