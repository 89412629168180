/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2023-06-16 00:08:53
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-16 00:42:14
 */
export default {
  /**
   * 获取图片路径
   */
  getImagePath (img) {
    if (!img) return ;
    return `https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/tikiShop/gamePic/${img}`
    
  }
}
