<template>
  <div id="app" :class="isMobile ? 'mobile':'pc'">
    <div
      :class="isGameDetailPage && isMobile ? 'components-layout game-detail' : 'components-layout'"
      ref="layout"
    >
      <a-layout>
        <a-layout-header>
          <headerVue />
        </a-layout-header>
        <a-layout-content>
          <router-view />
        </a-layout-content>
        <a-layout-footer>
          <footerVue />
        </a-layout-footer>
      </a-layout>
    </div>
  </div>
</template>

<script>
import headerVue from './views/header.vue';
import footerVue from './views/footer.vue';
export default {
  components: { footerVue, headerVue },
  computed: {
    isGameDetailPage() {
      return this.$route.path.includes('gameDetail');
    }
  },
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/reset.scss';
html.mobile {
  font-size: 13.3333vw;
  background: #1a1a1a;
}
#app {
  font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  &.pc {
    min-width: 1200px;
    overflow-x: auto;
  }
}
</style>

<style lang="scss" scoped>
.components-layout {
  .ant-layout-header {
    height: 68px;
    background: #222222;
    padding: 0 16px;
  }
  .ant-layout-footer {
    background: #1a1a1a;
    color: #fff;
    padding: 66px 16px 62px;
  }
  .ant-layout-content {
    background: #111111;
    color: #fff;
    min-height: calc(100vh - 68px);
  }
}

.mobile {
  .components-layout {
    &.game-detail {
      padding-bottom: px2rem(157);
    }
    .ant-layout-header {
      // height: px2rem(108);
      height: auto;
      padding: px2rem(28) px2rem(31);
    }
    .ant-layout-footer {
      background: #1a1a1a;
      color: #fff;
      padding: px2rem(50) px2rem(54);
    }
    .ant-layout-content {
      background: #111111;
      color: #fff;
      min-height: calc(100vh - 68px);
    }
  }
}
</style>
