/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2020-11-10 19:14:11
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-17 02:19:56
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "tikmobile" */ '@/views/index.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    component: () => import(/* webpackChunkName: "productList" */ '@/views/productList.vue')
  },
  {
    path: '/gameDetail/:id',
    name: 'payCheckout',
    component: () => import(/* webpackChunkName: "payCheckout" */ '@/views/payCheckout.vue')
  },
  {
    path: '/payConfirm/:gameId/:orderNo',
    name: 'payConfirm',
    component: () => import(/* webpackChunkName: "payConfirm" */ '@/views/payConfirm.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import(/* webpackChunkName: "payResult" */ '@/views/payResult.vue')
  },
  {
    path: '/rprivacy',
    name: 'rprivacy',
    component: () => import(/* webpackChunkName: "rprivacy" */ '@/views/rprivacy.vue')
  },
  {
    path: '/reula',
    name: 'reula',
    component: () => import(/* webpackChunkName: "reula" */ '@/views/reula.vue')
  },
  {
    path: '/raction',
    name: 'raction',
    component: () => import(/* webpackChunkName: "raction" */ '@/views/raction.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
