/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2023-06-01 23:59:00
 * @LastEditors: tina.cai
 * @LastEditTime: 2023-06-17 01:33:09
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    orderInfo: {
      gameName: '',
      gamePic: '',
      productItem: '',
      email: '',
      orderAmount: '',
      channelCode: '',
      customerId: '',
      username: '',
      serverId: '',
      platform: '',
      gameLogo: '',
    },
    country: '',
  },
  mutations: {
    setCountry(state, country) {
      state.country = country;
    },
    setCustomerId(state, customerId) {
      state.orderInfo.customerId = customerId;
    },
    setUsername(state, username) {
      state.orderInfo.username = username;
    },
    setServerId(state, serverId) {
      state.orderInfo.serverId = serverId;
    },
    setPlatform(state, platform) {
      state.orderInfo.productItem = platform;
    },
    setGameName(state, name) {
      state.orderInfo.gameName = name;
    },
    setGamePic(state, pic) {
      state.orderInfo.gamePic = pic;
    },
    setGameLogo(state, pic) {
      state.orderInfo.gameLogo = pic;
    },
    setProductItem(state, productItem) {
      state.orderInfo.productItem = productItem;
    },
    setEmail(state, email) {
      state.orderInfo.email = email;
    },
    setOrderAmount(state, orderAmount) {
      state.orderInfo.orderAmount = orderAmount;
    },
    setChannelCode(state, channelCode) {
      state.orderInfo.channelCode = channelCode;
    },
  },
  getters: {
    getOrderInfo(state) {
      return state.orderInfo;
    },
    getCountry(state) {
      return state.country
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})


export default store;
